<template>
  <div class="wrapper w-100">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="12" class="py-5" v-if="fetchingDataSale">
          <div class="text-center text-muted">
            <b-spinner></b-spinner>
            <br>
            Données de la vente...
          </div>
        </b-col>
        <b-col cols="12" v-else>
          <b-row class="mb-3 justify-content-end">
            <b-col class="add-blocks">
              <b-button variant="primary" class="addUnderSale" @click="addUnderSale">
                <i class="fa fa-plus mr-2"></i>
                Ajouter une sous-vente
              </b-button>
              <b-modal title="Enregistrer une sous vente" class="modal-primary" v-model="newUnderSaleModal"
                       @hide="onnewUnderSaleModalClose" size="lg">
                <b-form-row>
                  <b-col class="mr-4">
                    
                    <c-input container-class="mb-3" label="Nom de la vente" placeholder="Ex: Vente 01"
                             v-model="underSaleName" :state="underSaleNameState">
                      Veuillez saisir un nom
                    </c-input>
                    
                    <c-input container-class="mb-3" type="price" label="Prix de la vente" placeholder="Ex: 500"
                             v-model="underSaleAmount" :state="underSaleAmountState" :currency="exploitationCurrency">
                      Veuillez entrer un prix
                    </c-input>
                    
                    <c-input container-class="mb-3" type="quantity" label="Quantité vendue" placeholder="Ex: 50000"
                             v-model="underSaleQuantity" unit="Kg"
                             step="500" :state="underSaleQuantityState">
                      Veuillez spécifier une quantité valide
                    </c-input>
                    <b-alert show variant="danger" v-if="underSaleErrorMessage">{{underSaleErrorMessage}}</b-alert>
                  
                  </b-col>
                  <b-col>
                    <c-input container-class="mb-3" type="select" label="Personne qui a vendu"
                             v-model="underSaleStaff"
                             :options="salePersonWorking" :state="underSaleStaffState">
                      <template slot="first">
                        <option :value="null">-- Sélectionnez une personne --</option>
                      </template>
                      Veuillez sélectionner une personne
                    </c-input>
                    
                    <c-input container-class="mb-3" type="datetime" label="Date d'enregistrement"
                             v-model="underSaleDate"
                             :time.sync="underSaleTime" :state="underSaleDateTimeState">
                      Veuillez selectionner une durée
                    </c-input>
                    <c-input container-class="mb-3" type="textarea" label="Commentaire"
                             placeholder="Entrez un commentaire..." v-model="underSaleComment">
                    </c-input>
                  
                  </b-col>
                </b-form-row>
                
                <div slot="modal-footer" class="w-100 text-center">
                  <button-spinner variant="success" type="submit" class="px-4" @click="onSubmitNewUnderSale"
                                  :fetching="fetchingNewUnderSale">
                    Enregistrer
                  </button-spinner>
                  <b-button variant="secondary" @click="newUnderSaleModal = false" class="ml-2">
                    Annuler
                  </b-button>
                </div>
              </b-modal>
              <b-button variant="primary" class="addProductLost" @click="addLostProduct">
                <i class="fa fa-plus mr-2"></i>
                Ajouter une perte de produit
              </b-button>
              <b-modal title="Enregistrer une perte de produit" class="modal-primary" v-model="newLostProductModal"
                       @hide="onnewLostProductModalClose" size="lg">
                <b-form-row>
                  <b-col class="mr-4">
                    <p class="text-danger" v-if="hasError">{{errorMessage}}</p>
                    
                    <c-input container-class="mb-3" label="Nom de la perte" placeholder="Ex: Vente 01"
                             v-model="lostProductName" :state="lostProductNameState">
                      Veuillez saisir un nom
                    </c-input>
                    
                    
                    <c-input container-class="mb-3" type="quantity" label="Quantité perdue" placeholder="Ex: 50000"
                             v-model="lostProductQuantity" unit="Kg"
                             step="500" :state="lostProductQuantityState">
                      Veuillez spécifier une quantité
                    </c-input>
                    
                    <b-alert show variant="danger" v-if="lostProductErrorMessage">{{lostProductErrorMessage}}</b-alert>
                    <c-input container-class="mb-3 mt-3" type="select" label="Personne concernée"
                             v-model="lostProductStaff"
                             :options="salePersonWorking" :state="lostProductStaffState">
                      <template slot="first">
                        <option :value="null">-- Sélectionnez une personne --</option>
                      </template>
                      Veuillez sélectionner une personne
                    </c-input>
                  </b-col>
                  <b-col>
                    
                    <c-input container-class="mb-3" type="datetime" label="Date d'enregistrement"
                             v-model="lostProductDate"
                             :time.sync="lostProductTime" :state="lostProductDateTimeState">
                      Veuillez selectionner une durée
                    </c-input>
                    <c-input container-class="mb-3" type="textarea" label="Commentaire"
                             placeholder="Entrez un commentaire..." v-model="lostProductComment">
                    </c-input>
                  
                  </b-col>
                </b-form-row>
                
                <div slot="modal-footer" class="w-100 text-center">
                  <button-spinner variant="success" type="submit" class="px-4" @click="onSubmitNewLostProduct"
                                  :fetching="fetchingNewLostProduct">
                    Enregistrer
                  </button-spinner>
                  <b-button variant="secondary" @click="newLostProductModal = false" class="ml-2">
                    Annuler
                  </b-button>
                </div>
              </b-modal>
              <b-button variant="warning" class="modifyBaseInfos" @click="openSaleInformations">
                <i class="fa fa-plus mr-2"></i>
                Modifier les infos de base
              </b-button>
              <b-modal title="Informations de base" class="modal-primary" v-model="saleModal"
                       @hide="onAddModalSaveSaleClose" size="xl">
                <b-form-row>
                  <b-col class="mr-4" cols="6">
                    <c-input container-class="mb-3" label="Libellé de la vente" placeholder="Ex: Vente 1"
                             v-model="saleName" :state="saleNameState">
                      Veuillez saisir un nom
                    </c-input>
                    <c-input container-class="mb-3" type="quantity" label="Coût du transport" placeholder="Ex: 50000"
                             v-model="transportCost" :unit="exploitationCurrency"
                             step="500" :state="transportCostState">
                      Veuillez spécifier un coût
                    </c-input>
                    <b-form-row>
                      <b-col>
                        <c-input container-class="mb-3" type="select" label="Moyen de locomotion"
                                 v-model="transportMode"
                                 :options="transportModeList" :state="transportModeState">
                          <template slot="first">
                            <option :value="null">-- Select. le moyen de locomotion --</option>
                          </template>
                          Veuillez selectionner un moyen de locomotion
                        </c-input>
                      </b-col>
                      <b-col>
                        <c-input container-class="mb-3" type="select" label="Options de vente" v-model="saleOption"
                                 :options="saleOptionList" :state="saleOptionState">
                          <template slot="first">
                            <option :value="null">-- Selectionnez l'option de vente --</option>
                          </template>
                          Veuillez selectionner une option de vente
                        </c-input>
                      </b-col>
                    </b-form-row>
                    
                    <b-input-group v-if="staff.length">
                      <b-badge variant="secondary" pill class="m-1 py-1" v-for="item in staff">
                        {{item.name}}
                        <b-link @click.prevent="removeStaff(item)">
                          <i class="fa fa-remove text-danger"></i>
                        </b-link>
                      </b-badge>
                    </b-input-group>
                    <p class="text-center text-muted" v-else>Aucun employé dans cette activité.</p>
                    <b-input-group class="mb-3">
                      <b-autocomplete-input placeholder="Rechercher un employé..." v-model="staffSearch"
                                            :data="staffAutocompleteData" class="autocomplete w-100"
                                            @hit="addStaff($event)" ref="staffAutocompleteInput"
                                            :serializer="item => item.name"
                                            :minMatchingChars=0>
                        <template slot="append">
                          <b-button variant="primary" @click.prevent="addStaff()">
                            Ajouter
                          </b-button>
                        </template>
                      </b-autocomplete-input>
                    </b-input-group>
                    
                    <b-form-row>
                      <b-col>
                        <c-input container-class="mb-3" type="quantity" label="Prix de gros" placeholder="Ex: 50000"
                                 v-model="retailPrice" :unit="exploitationCurrency + ' / Kg'"
                                 step="500" :state="retailPriceState">
                          Veuillez spécifier un coût
                        </c-input>
                      </b-col>
                      <b-col>
                        <c-input container-class="mb-3" type="quantity" label="Prix en détail" placeholder="Ex: 50000"
                                 v-model="wholesalePrice" :unit="exploitationCurrency + ' / 100Kg'"
                                 step="500" :state="wholesalePriceState">
                          Veuillez spécifier un coût
                        </c-input>
                      </b-col>
                    </b-form-row>
                    
                    <c-input container-class="mb-3" type="datetime" label="Date de lancement" v-model="startDate"
                             :time.sync="startTime" :state="startDateTimeState">
                      Veuillez selectionnez une durée
                    </c-input>
                  </b-col>
                  <b-col>
                    <c-input type="image" v-model="picture" default="DEFAULT_INPUT_PICTURE"></c-input>
                    <c-input container-class="mb-3" type="quantity" label="Prevision des ventes" placeholder="Ex: 50000"
                             v-model="estimationSale" :unit="exploitationCurrency"
                             step="500" :state="estimationSaleState">
                      Veuillez spécifier un coût
                    </c-input>
                    <c-input container-class="mb-3" type="textarea" label="Description"
                             placeholder="Entrez une description..." v-model="comment">
                    </c-input>
                  </b-col>
                </b-form-row>
                <b-form-row class="b-a-1 p-1 justify-content-between">
                  <b-col cols="6">
                    <label class="font-weight-bold mb-0 text-muted">Répartitions dans les entrepôts</label>
                  </b-col>
                  <b-col cols="6">
                    <b-input-group class="font-weight-bold text-muted mt-2">
                      <b-input-group-prepend class="w-25">
                        <b-input-group-text class="w-100">Quantité totale =</b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="number" class="form-control"
                                    v-model="totalQuantity" disabled />
                      <b-input-group-append class="unit">
                        <b-input-group-text class="w-100">Kg</b-input-group-text>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                </b-form-row>
                <b-form-row class="p-2">
                  <b-col cols="4" v-for="(warehouse, i) in warehouses">
                    <b-input-group class="mb-3">
                      <b-input-group-prepend class="w-50">
                        <b-input-group-text class="w-100">{{warehouse.name}}</b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="number" class="form-control" placeholder="Qté"
                                    v-model="warehouse.quantity"
                                    min="0" step="10" disabled />
                      <b-input-group-append class="unit">
                        <b-input-group-text class="w-100">Kg</b-input-group-text>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                </b-form-row>
                <div slot="modal-footer" class="w-100 text-center">
                  <button-spinner variant="success" type="submit" class="px-4" @click="onSubmitSaveSale"
                                  :fetching=fetchingSaveSale>
                    Enregistrer
                  </button-spinner>
                  <b-button variant="secondary" @click="saleModal = false" class="ml-2">
                    Annuler
                  </b-button>
                </div>
              </b-modal>
              <b-button variant="danger" class="startStopSale" @click="launchingSale('close')" v-if="saleStatusName == 'RUNNING'">
                <b-spinner small class="mr-1" v-if="launching"></b-spinner>
                <i class="fa fa-play mr-1" v-else></i>
                Clôturer la vente
              </b-button>
              <b-button variant="success" class="startStopSale" @click="launchingSale('save-start')" v-else>
                <b-spinner small class="mr-1" v-if="launching"></b-spinner>
                <i class="fa fa-play mr-2" v-else></i>
                Lancer la vente
              </b-button>
              <b-button variant="dark" class="saveSpent" @click="newSpent">
                <i class="fa fa-plus mr-2"></i>
                Enregistrer une dépense
              </b-button>
              <b-modal title="Enregistrer une dépense" class="modal-primary" v-model="addModalSpent"
                       @hide="onAddModalSpentClose" size="lg">
                <b-form-row>
                  <b-col class="mr-4">
                    <c-input container-class="mb-3" label="Libellé de la dépense" placeholder="Ex: Dépense 1"
                             v-model="spentName" :state="spentNameState">
                      Veuillez saisir un nom
                    </c-input>
                    <c-input container-class="mb-3" type="quantity" label="Coût de la dépense" placeholder="Ex: 50000"
                             v-model="spentAmount" :unit="exploitationCurrency"
                             step="500" :state="spentAmountState">
                      Veuillez spécifier un coût
                    </c-input>
                    <div class="d-inline-flex w-100">
                      
                      <c-input container-class="mb-3" type="select" label="Catégorie de la dépense"
                               v-model="spentCategory"
                               :options="listSpentCategories" :state="spentCategoryState">
                        <template slot="first">
                          <option :value="null">-- Selectionnez la catégorie de la dépense --</option>
                        </template>
                        Veuillez sélectionner une personne
                      </c-input>
                      <b-button variant="dark" size="sm" pill class="add-button" title="Ajouter une nouvelle catégorie"
                                @click="addSpentCategory" :disabled="spentCategoryNewDisplay">
                        <i class="fa fa-plus"></i>
                      </b-button>
                    </div>
                    <c-input container-class="mb-3" label="" placeholder="Créer une nouvelle catégorie emetteur"
                             v-model="spentCategoryNew" :state="spentCategoryState" v-if="spentCategoryNewDisplay">
                      Veuillez saisir un nom
                    </c-input>
                    
                    
                    <c-input container-class="mb-3" type="datetime" label="Date d'enregistrement" v-model="spentDate"
                             :time.sync="spentTime">
                      Veuillez entrer une durée
                    </c-input>
                  </b-col>
                  <b-col>
                    <div class="d-inline-flex w-100">
                      <c-input container-class="mb-2" type="select" label="Emetteur de la dépense"
                               v-model="spentEmitter"
                               :options="spentsMembersList" :state="spentEmitterState">
                        <template slot="first">
                          <option :value="null">-- Selectionnez l'emetteur --</option>
                        </template>
                        Veuillez sélectionner une personne
                      </c-input>
                      <b-button variant="dark" size="sm" pill class="add-button" title="Ajouter un nouvel emetteur"
                                @click="addSpentEmitter" :disabled="spentEmitterNewDisplay">
                        <i class="fa fa-plus"></i>
                      </b-button>
                    </div>
                    <c-input container-class="mb-3" label="" placeholder="Créer un nouvel emetteur"
                             v-model="spentEmitterNew" :state="spentEmitterState" v-if="spentEmitterNewDisplay">
                      Veuillez saisir un nom
                    </c-input>
                    
                    <div class="d-inline-flex w-100">
                      <c-input container-class="mb-2" type="select" label="Bénéficiaire de la dépense"
                               v-model="spentReceiver"
                               :options="spentsMembersList" :state="spentReceiverState">
                        <template slot="first">
                          <option :value="null">-- Selectionnez le bénéficiaire --</option>
                        </template>
                        Veuillez sélectionner une personne
                      </c-input>
                      <b-button variant="dark" size="sm" pill class="add-button" title="Ajouter un nouvel emetteur"
                                @click="addReceiveEmitter" :disabled="spentReceiverNewDisplay">
                        <i class="fa fa-plus"></i>
                      </b-button>
                    </div>
                    <c-input container-class="mb-3" label="" placeholder="Créer un nouveau bénéficiaire"
                             v-model="spentReceiverNew" :state="spentReceiverState" v-if="spentReceiverNewDisplay">
                      Veuillez saisir un nom
                    </c-input>
                    
                    <c-input container-class="mb-3" type="textarea" label="Description de la dépense"
                             placeholder="Entrez une description..." v-model="spentDescription">
                    </c-input>
                  </b-col>
                  <b-form-file
                    v-model="spentFiles"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    multiple
                  ></b-form-file>
                </b-form-row>
                
                <div slot="modal-footer" class="w-100 text-center">
                  <button-spinner variant="success" type="submit" class="px-4" @click="onSubmitAddSpent"
                                  :fetching=fetchingCreateSpent>
                    Enregistrer
                  </button-spinner>
                  <b-button variant="secondary" @click="addModalSpent = false" class="ml-2">
                    Annuler
                  </b-button>
                </div>
              </b-modal>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="3">
              <b-card class="production-info text-white padding-conform">
                <b-dropdown right class="float-right information-dropdown text-white">
                  <template slot="button-content" variant="primary">
                    <i class="fa fa-th-list"></i>
                  </template>
                  <b-dropdown-item :to="saleList" active-class="">Toutes les ventes</b-dropdown-item>
                </b-dropdown>
                <h4 class="after-production">
                  {{saleProgressionPercentage}}
                  %
                </h4>
                <p class="text-stat salePercent">
                  {{saleDisplayName}}
                  <span class="d-block mt-1">
                    <b-badge class="font-sm cursor-pointer" :variant="saleStatusVariant"
                             :title="saleStatusDisplayName + saleStartDate"
                             v-b-tooltip.hover>
                      {{saleStatusName}}
                    </b-badge>
                  </span>
                </p>
              </b-card>
            </b-col>
            <b-col cols="3">
              <b-card class="person-number-info text-white padding-conform">
                <b-dropdown right class="float-right information-dropdown text-white">
                  <template slot="button-content" variant="primary">
                    <i class="fa fa-th-list"></i>
                  </template>
                  <b-dropdown-item>Utilisateurs ({{salePersonWorkingUserLength}})</b-dropdown-item>
                  <b-dropdown-item>Employés ({{salePersonWorkingEmployeeLength}})</b-dropdown-item>
                </b-dropdown>
                <h4 class="mb-0">{{salePersonWorkingUserLength + salePersonWorkingEmployeeLength}}</h4>
                <p class="text-stat saleWorkers"
                   title="">
                  personnes travaillent sur cette production
                </p>
              </b-card>
            </b-col>
            <b-col cols="3">
              <b-card class="quantity-info text-white padding-conform">
                <b-dropdown right class="float-right information-dropdown text-white">
                  <template slot="button-content" variant="primary">
                    <i class="fa fa-th-list"></i>
                  </template>
                  <b-dropdown-item>Quantité vendue ({{underSalesTotalQuantity}} Kg)</b-dropdown-item>
                  <b-dropdown-item>Pertes de Produits ({{lostProductsTotalQuantity}} Kg)</b-dropdown-item>
                </b-dropdown>
                <h4 class="mb-0">{{saleRemainingQuantity}} Kg</h4>
                <p class="text-stat saleQuantityRemaining"
                   title="">
                  de {{saleCultureName}} restant(s)
                </p>
              </b-card>
            </b-col>
            <b-col cols="3">
              <b-card class="spent-money-info text-white padding-conform">
                <b-dropdown right class="float-right information-dropdown pr-0">
                  <template slot="button-content" variant="primary" class="information-dropdown">
                    <i class="fa fa-th-list"></i>
                  </template>
                  <b-dropdown-item>Dépenses ({{spentsAmount + ' ' + exploitationCurrency}})</b-dropdown-item>
                  <b-dropdown-item>Comptes</b-dropdown-item>
                </b-dropdown>
                <h4 class="mb-0">{{saleAmountEnter + ' ' + exploitationCurrency}}</h4>
                <p class="text-stat saleSpentAmount">d'entrée(s) effectuée(s)</p>
              </b-card>
            </b-col>
          </b-row>
          <b-card header-tag="header" header-bg-variant="transparent">
            <div slot="header">
              Autres informations
            </div>
            <b-button variant="success" @click="onExport" title="Exporter en PDF" class="float-right mr-2 mb-2"
                      v-b-tooltip.hover v-if="displayPrint">
              <i class="fa fa-upload"></i>
            </b-button>
            <b-tabs v-model="listOpen">
              <b-tab>
                <template slot="title" class="px-2">
                  <div class="underSales">
                  <i class="fa fa-balance-scale"></i> Sous-ventes ({{underSalesData.length}})
                  </div>
                </template>
                <b-row>
                  <c-table :table-data="underSalesData" :fields="underSalesFields" :actions="underSaleActions"
                           :per-page=5
                           striped outlined fixed>
                    <template slot="empty">
                      <p class="text-center">Aucune sous vente enregistrée.</p>
                    </template>
                  </c-table>
                </b-row>
              </b-tab>
              <b-tab>
                <template slot="title" class="">
                  <div class="lostProducts">
                  <i class="fa fa-binoculars"></i> Pertes de Produits ({{lostProductsData.length}})
                  </div>
                </template>
                <b-row>
                  <c-table :table-data="lostProductsData" :fields="lostProductsFields" :actions="lostProductActions"
                           :per-page=5
                           striped outlined fixed>
                    <template slot="empty">
                      <p class="text-center">Aucune perte de produit enregistrée.</p>
                    </template>
                  </c-table>
                </b-row>
              </b-tab>
              <b-tab>
                <template slot="title" class="">
                  <div class="salers">
                  <i class="icon-people"></i> Vendeurs ({{usersSalesData.length}})
                  </div>
                </template>
                <b-row>
                  <c-table :table-data="usersSalesData" :fields="userFields" :actions="userActions"
                           :per-page=5
                           striped outlined fixed>
                    <template slot="empty">
                      <p class="text-center">Aucun vendeur enregistré.</p>
                    </template>
                  </c-table>
                </b-row>
              </b-tab>
              <b-tab>
                <template slot="title" class="">
                  <div class="spents">
                  <i class="icon-people"></i> Dépenses ({{spentSalesData.length}})
                  </div>
                </template>
                <b-row>
                  <c-table :table-data="spentSalesData" :fields="spentFields" :actions="spentActions"
                           :per-page=5
                           striped outlined>
                    <template slot="empty">
                      <p class="text-center">Aucune dépense enregistrée.</p>
                    </template>
                  </c-table>
                </b-row>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </div>
  
  </div>
</template>

<script>
  import {Api, asset, env, price, Toast, Regex, IO} from "../../../helpers";
  
  export default {
    name: "ExploitationSale",
    title:"PIA - Ventes",
    data() {
      return {
        fetchingDataSale: false,
        sale: null,
        
        listOpen: 0,
        underSalesFields: [
          {key: 'name', label: 'Nom'},
          {key: 'qty', label: 'Quantité vendue'},
          {key: 'priceCurrency', label: 'Prix'},
          {key: 'user.name', label: 'Vendu par'},
          {key: 'formattedDate', label: 'Date de vente'},
          {key: 'actions', label: 'Actions'},
        ],
        underSaleActions: [
          {type: 'success', icon: 'icon-size-fullscreen', title: 'Ouvrir', handler: this.moreInfoUnderSale},
          {type: 'success', icon: 'fa fa-upload', title: 'Impimer la sous-vente', handler: this.printUnderSale},
        ],
        
        lostProductsFields: [
          {key: 'name', label: 'Nom'},
          {key: 'qty', label: 'Quantité perdue'},
          {key: 'user.name', label: 'Effectuée par'},
          {key: 'formattedDate', label: 'Date'},
          {key: 'actions', label: 'Actions'},
        ],
        lostProductActions: [
          {type: 'success', icon: 'icon-size-fullscreen', title: 'Ouvrir', handler: this.moreInfoLostProduct},
          {type: 'success', icon: 'fa fa-upload', title: 'Impimer la perte de produit', handler: this.printLostProduct},
        ],
        
        userFields: [
          {key: 'picture', label: 'Photo', asset: this.asset},
          {key: 'name', label: 'Nom et Prénoms'},
          {key: 'phone_email', label: 'Téléphone/email'},
          {key: 'address', label: 'Addresse'},
          {key: 'actions', label: 'Actions'},
        ],
        userActions: [
          {type: 'success', icon: 'icon-size-fullscreen', title: 'Ouvrir', handler: this.userDetails},
        ],
        
        spentFields: [
          {key: 'name', label: 'Libellé de la dépense'},
          {key: 'amountCurrency', label: 'Montant'},
          {key: 'spentEmitter.name', label: 'Effectuée par'},
          {key: 'formattedDate', label: 'Enregistrée le'},
          {key: 'actions', label: 'Actions'},
        ],
        spentActions: [
          {type: 'success', icon: 'icon-size-fullscreen', title: 'Ouvrir', handler: this.spentDetails},
        ],
        
        saleModal: false,
        picture: null,
        saleName: '',
        staff: [],
        transportCost: '',
        transportMode: null,
        warehouses: [],
        retailPrice: '',
        wholesalePrice: '',
        saleOption: null,
        estimationSale: '',
        startDate: '',
        startTime: '',
        currentCulture: null,
        comment: '',
        staffList: [],
        
        transportModeList: [],
        saleOptionList: [],
        
        launching: false,
        
        newUnderSaleModal: false,
        underSaleName: '',
        underSaleAmount: '',
        underSaleQuantity: '',
        underSaleStaff: null,
        underSaleDate: '',
        underSaleTime: '',
        underSaleComment: '',
        fetchingNewUnderSale: false,
        submittedUnderSale: false,
        underSaleError: null,
        
        newLostProductModal: false,
        lostProductName: '',
        lostProductAmount: '',
        lostProductQuantity: '',
        lostProductStaff: null,
        lostProductDate: '',
        lostProductTime: '',
        lostProductComment: '',
        fetchingNewLostProduct: false,
        submittedLostProduct: false,
        lostProductError: null,
        
        fetchingSaveSale: false,
        submittedSaveSale: false,
        
        spentName: '',
        spentAmount: '',
        
        spentCategory: null,
        spentCategoryNew: "",
        spentCategoryNewDisplay: false,
        
        spentDate: "",
        spentTime: "",
        spentEmitter: null,
        spentReceiver: null,
        spentEmitterNew: "",
        spentReceiverNew: "",
        spentDescription: '',
        fetchingCreateSpent: false,
        submittedSpent: false,
        spentFiles: [],
        addModalSpent: false,
        
        editModalSpent: false,
        spentsMembers: [],
        spentEmitterNewDisplay: false,
        spentReceiverNewDisplay: false,
        
        spentCategories: [],
  
      }
    },
    created() {
      this.fetchingDataSale = true
      Api.get('/exploitation/product-sale', {
        exploitationId: this.exploitationId,
        saleId: this.saleId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            const result = res.data.data
            this.sale = {
              ...result,
              saleOptionId: result.saleOption.id,
              transportModeId: result.transportMode.id,
              warehouses: result.warehouses.map(ware => ({
                ...ware,
                warehouseId: ware.id
              }))
            }
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingDataSale = false
          this.$nextTick(()=>{
            this.$tours['myTour'].start()
          })
        })
      Api.get('/exploitation/staff/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.staffList = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingStaffList = false
        })
      Api.get('/exploitation/transport-mode/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.transportModeList = res.data.data.map(mode => ({
              value: mode.id,
              text: mode.name
            }))
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
        
        })
      Api.get('/exploitation/sale-option/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.saleOptionList = res.data.data.map(option => ({
              value: option.id,
              text: option.name
            }))
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
        })
      Api.get('/exploitation/member/all', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.spentsMembers = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
        })
      Api.get('/exploitation/spent-category/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.spentCategories = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
        })
    },
    mounted() {
    
    },
    watch: {},
    computed: {
      exploitationId() {
        return this.$store.getters.exploitationId
      },
      saleId() {
        return this.$route.params.saleId
      },
      saleList() {
        return '/exploitation/' + this.exploitationId + '/sales/list'
      },
      exploitationCurrency() {
        return price()
      },
      saleDisplayName() {
        return this.sale.name
      },
      saleStatusName() {
        return this.sale.status.name
      },
      saleStatusVariant() {
        return this.sale.status.variant
      },
      saleStartDate() {
        return this.sale.startDate.split(' ').join(' à ')
      },
      saleStatusDisplayName() {
        if (this.saleStatusName == 'RUNNING')
          return 'En cours depuis le '
        else if (this.saleStatusName == 'PLANNED')
          return 'Planifiée pour le '
        else
          return 'Terminée le '
      },
      salePersonWorkingUserLength() {
        return this.sale.staff.filter(item => item.user).length
      },
      salePersonWorkingEmployeeLength() {
        return this.sale.staff.filter(item => !item.user).length
      },
      salePersonWorking() {
        return this.sale.staff.map(item => ({
          text: item.name,
          value: item
        }))
      },
      salePersonWorkingInUnderSaleOrLostProduct() {
        let result = []
        result = this.sale.underSales.reduce((acc, val) => {
          if (!result.find(el => el.id == val.user.id && el.user == val.user.user))
            return result.concat(val.user)
          else return acc
        }, result)
        result = this.sale.lostProducts.reduce((acc, val) => {
          if (!result.find(el => el.id == val.user.id && el.user == val.user.user))
            return result.concat(val.user)
          else return acc
        }, result)
        
        return result
      },
      underSalesTotalQuantity() {
        return this.sale.underSales.reduce((acc, val) => {
          return acc + val.quantity
        }, 0)
      },
      lostProductsTotalQuantity() {
        return this.sale.lostProducts.reduce((acc, val) => {
          return acc + val.quantity
        }, 0)
      },
      saleRemainingQuantity() {
        return this.sale.quantity - this.underSalesTotalQuantity - this.lostProductsTotalQuantity
      },
      saleAmountEnter() {
        return this.sale.underSales.reduce((acc, val) => {
          return acc + val.price
        }, 0)
      },
      saleProgressionPercentage() {
        return (this.underSalesTotalQuantity * 100 / (this.sale.quantity - this.lostProductsTotalQuantity)).toFixed(2)
      },
      saleCultureName() {
        return this.sale.culture.name
      },
      underSalesData() {
        return this.sale.underSales.map(underSale => ({
          ...underSale,
          qty: underSale.quantity + ' Kg',
          formattedDate: underSale.saleDate.split(' ').join(' à '),
          priceCurrency: underSale.price + ' ' + this.exploitationCurrency
        }))
      },
      lostProductsData() {
        return this.sale.lostProducts.map(lostProduct => ({
          ...lostProduct,
          qty: lostProduct.quantity + ' Kg',
          formattedDate: lostProduct.lossDate.split(' ').join(' à ')
        }))
      },
      usersSalesData() {
        return this.sale.staff.map(userSale => ({
          ...userSale,
          phone_email: userSale.user ? userSale.email : userSale.phone_number
        }))
      },
      spentSalesData() {
        return this.sale.spents.map(spentSale => ({
          ...spentSale,
          amountCurrency: spentSale.amount + ' ' + this.exploitationCurrency,
          formattedDate: spentSale.spentDate.split(' ').join(' à ')
        }))
      },
      spentsAmount() {
        return this.sale.spents.reduce((acc, val) => {
          return acc + val.amount
        }, 0)
      },
      staffAutocompleteData() {
        return this.staffList
      },
      totalQuantity() {
        return this.warehouses.reduce((acc, val) => {
          if (val) return acc + val.quantity
          else return acc
        }, 0)
      },
      hasError() {
        return !!this.error
      },
      errorMessage() {
        return this.error ? this.error.message : null
      },
      underSaleErrorMessage() {
        return this.underSaleError ? this.underSaleError : null
      },
      underSaleNameState() {
        return !this.submittedUnderSale || this.underSaleName.trim().length >= 3 ? null : false
      },
      underSaleAmountState() {
        return !this.submittedUnderSale || Regex.isPositiveNumber(this.underSaleAmount) ? null : false
      },
      underSaleQuantityState() {
        return !this.submittedUnderSale ||
        (Regex.isPositiveNumber(this.underSaleQuantity) &&
          this.underSaleQuantity <= this.saleRemainingQuantity) ? null : false
      },
      underSaleStaffState() {
        return !this.submittedUnderSale || this.underSaleStaff != null ? null : false
      },
      underSaleDateTimeState() {
        return !this.submittedUnderSale || (this.underSaleDate != "" && this.underSaleTime != "") ? null : false
      },
      lostProductErrorMessage() {
        return this.lostProductError ? this.lostProductError : null
      },
      lostProductNameState() {
        return !this.submittedLostProduct || this.lostProductName.trim().length >= 3 ? null : false
      },
      lostProductQuantityState() {
        return !this.submittedLostProduct ||
        (Regex.isPositiveNumber(this.lostProductQuantity) &&
          this.lostProductQuantity <= this.saleRemainingQuantity) ? null : false
      },
      lostProductStaffState() {
        return !this.submittedLostProduct || this.lostProductStaff != null ? null : false
      },
      lostProductDateTimeState() {
        return !this.submittedLostProduct || (this.lostProductDate != "" && this.lostProductTime != "") ? null : false
      },
      displayPrint() {
        switch (this.listOpen) {
          case 0:
            return this.underSalesData.length > 0
          case 1:
            return this.lostProductsData.length > 0
          default:
            return this.usersSalesData.length > 0
        }
      },
      saleNameState() {
        return !this.submittedSaveSale || this.saleName.trim().length > 3 ? null : false
      },
      transportCostState() {
        return !this.submittedSaveSale || Regex.isPositiveNumber(this.transportCost) ? null : false
      },
      transportModeState() {
        return !this.submittedSaveSale || this.transportMode != null ? null : false
      },
      saleOptionState() {
        return !this.submittedSaveSale || this.saleOption != null ? null : false
      },
      retailPriceState() {
        return !this.submittedSaveSale || Regex.isPositiveNumber(this.retailPrice) ? null : false
      },
      wholesalePriceState() {
        return !this.submittedSaveSale || Regex.isPositiveNumber(this.wholesalePrice) ? null : false
      },
      startDateTimeState() {
        return !this.submittedSaveSale || this.startDate != "" ? null : false
      },
      estimationSaleState() {
        return !this.submittedSaveSale || Regex.isPositiveNumber(this.estimationSale) ? null : false
      },
      spentNameState() {
        return !this.submittedSpent || this.spentName.trim().length >= 3 ? null : false
      },
      spentAmountState() {
        return !this.submittedSpent || Regex.isPositiveNumber(this.spentAmount) ? null : false
      },
      spentCategoryState() {
        return !this.submittedSpent || (this.spentCategory != null || this.spentCategoryNew.trim().length >= 3) ? null : false
      },
      spentEmitterState() {
        return !this.submittedSpent || (this.spentEmitter != null || this.spentEmitterNew.trim().length >= 3) ? null : false
      },
      spentReceiverState() {
        return !this.submittedSpent || (this.spentReceiver != null || this.spentReceiverNew.trim().length >= 3) ? null : false
      },
      listSpentCategories() {
        return this.spentCategories.map(cat => ({
          text: cat.name,
          value: cat
        }))
      },
      spentsMembersList() {
        return this.spentsMembers.map(member => ({
          text: member.name,
          value: member
        }))
      },
      startDateTime() {
        if (this.startTime)
          return this.startDate + ' ' + this.startTime
        return this.startDate
      }
    },
    methods: {
      asset(path) {
        return asset(path, env('DEFAULT_USER_PICTURE'))
      },
      addStaff(item) {
        if (!item && this.staffSearch.trim() !== '')
          item = this.staffAutocompleteData.find(
            el => el.name.toLowerCase().includes(this.staffSearch.toLowerCase())
          )
        
        if (item) {
          // todo le faire partout
          if (!this.staff.find(el => el.id == item.id && el.user == item.user))
            this.staff.push(item)
        }
        else
          alert('Aucun élément trouvé !')
        
        this.clearStaffAutocompleteInput()
      },
      removeStaff(item) {
        if (this.salePersonWorkingInUnderSaleOrLostProduct.find(el => el.id == item.id && el.user == item.user)) {
          Toast.error('Cet utilisateur est deja impliqué dans une sous-vente/perte de produit')
          return
        }
        this.staff = this.staff.filter(el => el.id !== item.id || el.user !== item.user)
      },
      
      clearStaffAutocompleteInput() {
        this.$refs.staffAutocompleteInput.inputValue = ''
        this.staffSearch = ''
      },
      openSaleInformations() {
        const saleItem = this.sale
        this.saleName = saleItem.name
        this.transportCost = saleItem.transportCost
        this.transportMode = saleItem.transportMode.id
        this.saleOption = saleItem.saleOption.id
        this.staff = saleItem.staff
        this.retailPrice = saleItem.retailPrice
        this.wholesalePrice = saleItem.wholesalePrice
        this.startDate = saleItem.startDate.split(' ')[0].split('/').reverse().join('-')
        this.startTime = saleItem.startDate.split(' ')[1]
        this.picture = this.asset(saleItem.picture)
        this.estimationSale = saleItem.estimationSale
        this.comment = saleItem.comment
        this.warehouses = saleItem.warehouses.map(warehouse => ({
          warehouseId: warehouse.id,
          name: warehouse.name,
          quantity: warehouse.quantity
        }))
        this.saleModal = true
      },
      launchingSale(mode) {
        this.launching = true
        Api.post(`/exploitation/product-sale/${mode}`, {
          exploitationId: this.exploitationId,
          saleId: this.saleId,
          ...this.sale
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.sale = res.data.data
              if (mode == 'save-start')
                Toast.success('Vente lancée avec succès')
              else Toast.success('Vente cloturée avec succes')
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.launching = false
          })
      },
      addUnderSale() {
        if (this.saleStatusName == 'PLANNED') {
          Toast.error("Veuillez lancer la vente pour pouvoir enregistrer une sous-vente")
          return
        }
        this.newUnderSaleModal = true
      },
      onnewUnderSaleModalClose() {
        this.underSaleName = ''
        this.underSaleAmount = ''
        this.underSaleQuantity = ''
        this.underSaleStaff = null
        this.underSaleComment = ''
        this.underSaleDate = ''
        this.underSaleTime = ''
        this.underSaleError = null
        this.submittedUnderSale = false
        
        this.fetchingNewUnderSale = false
      },
      addLostProduct() {
        this.newLostProductModal = true
      },
      onnewLostProductModalClose() {
        this.lostProductName = ''
        this.lostProductQuantity = ''
        this.lostProductStaff = null
        this.lostProductComment = ''
        this.lostProductDate = ''
        this.lostProductTime = ''
        this.lostProductError = null
        this.submittedLostProduct = false
        
        this.fetchingNewLostProduct = false
      },
      onSubmitNewUnderSale() {
        this.submittedUnderSale = true
        if (!this.validUnderSale()) return
        this.fetchingNewUnderSale = true
        Api.post('/exploitation/product-sale/under-sale/create', {
          exploitationId: this.exploitationId,
          saleId: this.saleId,
          name: this.underSaleName,
          quantity: this.underSaleQuantity,
          price: this.underSaleAmount,
          staff: this.underSaleStaff,
          comment: this.underSaleComment,
          saleDate: this.underSaleDate + ' ' + this.underSaleTime
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.sale.underSales.push(res.data.data)
              Toast.success('Enregistrement effectue')
              this.onnewUnderSaleModalClose()
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.submittedUnderSale = false
          })
      },
      onSubmitNewLostProduct() {
        this.submittedLostProduct = true
        if (!this.validLostProduct()) return
        this.fetchingNewLostProduct = true
        Api.post('/exploitation/product-sale/lost-product/create', {
          exploitationId: this.exploitationId,
          saleId: this.saleId,
          name: this.lostProductName,
          quantity: this.lostProductQuantity,
          staff: this.lostProductStaff,
          comment: this.lostProductComment,
          lossDate: this.lostProductDate + ' ' + this.lostProductTime
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.sale.lostProducts.push(res.data.data)
              Toast.success('Enregistrement effectue')
              this.newLostProductModal = false
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.submittedLostProduct = false
          })
      },
      validUnderSale() {
        if (!Regex.isPositiveNumber(this.underSaleQuantity)) return false
        if (this.underSaleQuantity > this.saleRemainingQuantity) {
          this.underSaleError = "La quantité specifiée doit être inférieure ou égale à " + this.saleRemainingQuantity +
            " Kg(ce qui reste dans le stock)"
          return false
        }
        return this.underSaleName.trim().length >= 3
          && Regex.isPositiveNumber(this.underSaleAmount)
          && this.underSaleStaff != null
          && this.underSaleDate != ""
          && this.underSaleTime != ""
      },
      validLostProduct() {
        if (!Regex.isPositiveNumber(this.lostProductQuantity)) return false
        if (this.lostProductQuantity > this.saleRemainingQuantity) {
          this.lostProductError = "La quantité specifiée doit être inférieure ou égale à " + this.saleRemainingQuantity +
            " Kg(ce qui reste dans le stock)"
          return false
        }
        return this.lostProductName.trim().length >= 3
          && this.lostProductStaff != null
          && this.lostProductDate != ""
          && this.lostProductTime != ""
      },
      printUnderSale(underSale) {
        IO.exportDataTable([underSale], 'Sous-vente', {
          title: `Exploitation: ${this.$store.getters.exploitationName}\n Vente : ${this.sale.name}`,
          name: 'Sous vente : ' + underSale.name,
          headers: [
            {title: 'Nom', key: 'name', width: 'auto'},
            {title: 'Quantité', key: 'qty', width: 'auto'},
            {title: 'P.U', key: 'priceCurrency', width: 'auto'},
            {title: 'Vendu par', key: 'user.name', width: 'auto'},
            {title: 'Vendu le', key: 'formattedDate', width: 'auto'},
            {title: 'Commentaire', key: 'comment', width: '*'},
          ]
        })
      },
      printLostProduct(lostProduct) {
        IO.exportDataTable([lostProduct], 'Sous-vente', {
          title: `Exploitation: ${this.$store.getters.exploitationName} \n Vente : ${this.sale.name}`,
          name: 'Sous vente : ' + lostProduct.name,
          headers: [
            {title: 'Nom', key: 'name', width: 'auto'},
            {title: 'Quantité', key: 'qty', width: 'auto'},
            {title: 'P.U', key: 'priceCurrency', width: 'auto'},
            {title: 'Vendu par', key: 'user.name', width: 'auto'},
            {title: 'Vendu le', key: 'formattedDate', width: 'auto'},
            {title: 'Commentaire', key: 'comment', width: '*'},
          ]
        })
      },
      onExport() {
        switch (this.listOpen) {
          case 0:
            IO.exportDataTable(this.underSalesData, 'sous-ventes', {
              title: `Exploitation: ${this.$store.getters.exploitationName} \n Vente : ${this.sale.name}`,
              name: 'Listes des sous-ventes',
              headers: [
                {title: 'Nom', key: 'name', width: 'auto'},
                {title: 'Quantité', key: 'qty', width: 'auto'},
                {title: 'P.U', key: 'priceCurrency', width: 'auto'},
                {title: 'Vendu par', key: 'user.name', width: 'auto'},
                {title: 'Vendu le', key: 'formattedDate', width: 'auto'},
                {title: 'Commentaire', key: 'comment', width: '*'},
              ]
            })
            break;
          case 1:
            IO.exportDataTable(this.lostProductsData, 'pertes-produits', {
              title: `Exploitation: ${this.$store.getters.exploitationName} \n Vente : ${this.sale.name}`,
              name: 'Listes des pertes de produit',
              headers: [
                {title: 'Nom', key: 'name', width: 'auto'},
                {title: 'Quantité', key: 'qty', width: 'auto'},
                {title: 'Concernée', key: 'user.name', width: 'auto'},
                {title: 'Perdu le', key: 'formattedDate', width: 'auto'},
                {title: 'Commentaire', key: 'comment', width: '*'},
              ]
            })
            break;
          default :
            IO.exportDataTable(this.usersSalesData, 'vendeurs', {
              title: `Exploitation: ${this.$store.getters.exploitationName} \n Vente : ${this.sale.name}`,
              name: 'Listes des vendeurs',
              headers: [
                {key: 'picture', title: 'Photo', width: 'auto'},
                {key: 'name', title: 'Nom et Prénoms', width: 'auto'},
                {key: 'phone_email', title: 'Téléphone/email', width: 'auto'},
                {key: 'address', title: 'Addresse', width: 'auto'},
                {title: 'Commentaire', key: 'comment', width: '*'},
              ]
            })
            break;
        }
      },
      onSubmitSaveSale() {
        this.submittedSaveSale = true
        if (!this.validSaveSale()) return
        this.fetchingSaveSale = true
        Api.post('/exploitation/product-sale/save', {
          exploitationId: this.exploitationId,
          saleId: this.saleId,
          cultureId: this.sale.culture.id,
          picture: Regex.isNullOrURL(this.picture) ? null : this.picture,
          name: this.saleName,
          staff: this.staff,
          transportCost: this.transportCost,
          transportModeId: this.transportMode,
          warehouses: this.warehouses,
          retailPrice: this.retailPrice,
          wholesalePrice: this.wholesalePrice,
          saleOptionId: this.saleOption,
          estimationSale: this.estimationSale,
          startDate: this.startDateTime,
          comment: this.comment
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.sale = res.data.data
              Toast.success('Enregistre avec succes')
              this.saleModal = false
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingSaveSale = false
          })
      },
      onAddModalSaveSaleClose() {
        this.submittedSaveSale = false
      },
      validSaveSale() {
        return this.saleName.trim().length > 3
          && Regex.isPositiveNumber(this.transportCost)
          && this.transportMode != null
          && this.saleOption != null
          && this.startDate != ""
          && Regex.isPositiveNumber(this.retailPrice)
          && Regex.isPositiveNumber(this.wholesalePrice)
          && Regex.isPositiveNumber(this.estimationSale)
      },
      onAddModalSpentClose() {
        
        this.editModalSpent = false
        this.spentName = ''
        this.spentAmount = ''
        this.spentDescription = ''
        this.submittedSpent = false
        this.spentDate = ""
        this.spentTime = ""
        this.spentReceiver = null
        this.spentReceiverNew = ""
        this.spentReceiverNewDisplay = false
        this.spentEmitter = null
        this.spentEmitterNew = ""
        this.spentEmitterNewDisplay = false
        
        this.spentCategoryNew = ""
        this.spentCategoryNewDisplay = false
      },
      newSpent() {
        this.addModalSpent = true
      },
      validSpent() {
        return this.spentName.trim().length >= 3 &&
          Regex.isPositiveNumber(this.spentAmount)
          && (this.spentCategory != null || this.spentCategoryNew.trim().length >= 3)
          && (this.spentEmitter != null || this.spentEmitterNew.trim().length >= 3)
          && (this.spentReceiver != null || this.spentReceiverNew.trim().length >= 3)
      },
      getFiles(files) {
        return Promise.all(files.map(file => {
          var reader = new FileReader()
          return new Promise((resolve, reject) => {
            reader.onload = (ev => {
              resolve(ev.target.result)
            })
            reader.readAsDataURL(file)
          })
        }))
      },
      async onSubmitAddSpent() {
        this.submittedSpent = true
        if (!this.validSpent())
          return
        
        this.fetchingCreateSpent = true
        
        const base64Files = await this.getFiles(this.spentFiles)
        
        // recuperation de l'emetteur
        const emitter = {
          isExist: this.spentEmitter ? true : false,
          user: this.spentEmitter || this.spentEmitterNew
        }
        // recuperation du beneficiaire
        const receiver = {
          isExist: this.spentReceiver ? true : false,
          user: this.spentReceiver || this.spentReceiverNew
        }
        
        // retrieving the old|new mode
        const cat = {
          isExist: this.spentCategory ? true : false,
          category: this.spentCategory || this.spentCategoryNew
        }
        
        let createOrUpdate = ""
        if (this.editModalSpent) createOrUpdate = 'update'
        else createOrUpdate = 'create'
        
        Api.post(`/exploitation/product-sale/spent/${createOrUpdate}`, {
          exploitationId: this.exploitationId,
          saleId: this.saleId,
          spentId: this.spentEditId,
          name: this.spentName,
          amount: this.spentAmount,
          spentEmitter: emitter,
          spentReceiver: receiver,
          spentCategory: cat,
          spentDate: this.spentDate + " " + this.spentTime,
          description: this.spentDescription,
          attachments: base64Files,
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              if (createOrUpdate == 'create') {
                this.sale.spents.push(res.data.data)
                this.addModalSpent = false
                Toast.success('Dépense enregistrée avec succès')
              } else {
                this.sale.spents = this.sale.spents.filter(ca => ca.id != this.spentEditId)
                this.sale.spents.push(res.data.data)
                this.addModalSpent = false
                Toast.success('Dépense modifiée avec succès')
              }
              this.refreshCategoryList()
              this.refreshSpentCategoryList()
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingCreateSpent = false
          })
      },
      addSpentCategory() {
        this.spentCategoryNewDisplay = true
      },
      addSpentEmitter() {
        this.spentEmitterNewDisplay = true
      },
      addReceiveEmitter() {
        this.spentReceiverNewDisplay = true
      },
      refreshCategoryList() {
        
        Api.get('/exploitation/member/all', {
          exploitationId: this.exploitationId
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.spentsMembers = res.data.data
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
          })
      },
      refreshSpentCategoryList() {
        
        Api.get('/exploitation/spent-category/list', {
          exploitationId: this.exploitationId
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.spentCategories = res.data.data
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
          })
      },
      spentDetails(spent) {
        
        this.addModalSpent = true
        this.editModalSpent = true
        
        
        this.spentEditId = spent.id
        this.spentName = spent.name
        this.spentAmount = spent.amount
        this.spentDescription = spent.description
        this.spentDate = spent.spentDate.split(' ')[0].split('/').reverse().join('-')
        this.spentTime = spent.spentDate.split(' ')[1]
        this.spentEmitter = this.spentsMembers.find(m => m.id == spent.spentEmitter.id
          && m.user == spent.spentEmitter.user)
        this.spentReceiver = this.spentsMembers.find(m => m.id == spent.spentReceiver.id
          && m.user == spent.spentReceiver.user)
        this.spentCategory = this.spentCategories.find(entry => entry.id == spent.spentCategory.id)
      }
    },
  }
</script>

<style>
  .information-dropdown .btn.btn-secondary{
    background-color: transparent !important;
    border-color: transparent !important;
    color: white !important;
  }
  
  .information-dropdown .btn.btn-secondary:hover{
    background-color: rgba(231, 231, 231, .4) !important;
  }

</style>
<style scoped>
  .production-info{
    background: linear-gradient(45deg, #321fdb, #1f1498);
  }
  
  .person-number-info{
    background: linear-gradient(45deg, #39f, #2982cc);
  }
  
  .quantity-info{
    background: linear-gradient(20deg, #F2A65A, #772F1A);
  }
  
  .current-step-info{
    background: linear-gradient(45deg, #f9b115, #f6960b);
  }
  
  .spent-money-info{
    background: linear-gradient(45deg, #E0D4AE, #A38560);
  }
  
  .text-stat{
    height: 4.7em;
    text-overflow: ellipsis;
    margin-top: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .after-production::after{
    font-size: 14px;
    content: " \2022 Pour moi";
    font-family: "Segoe UI Historic";
  }
  
  .padding-conform .card-body{
    padding: 1rem .3rem 0rem 1rem !important;
  }
  
  .add-blocks > button:not(:last-child){
    margin-right: 20px;
  }
  
  .add-button{
    height: 30px;
    width: 30px;
    margin-top: 23px;
    margin-left: 10px;
    margin-right: 2px;
  }

</style>
